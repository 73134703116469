import '../css/Home.css';
import web_cert from "../assets/web-dev-cert.jpg"
import dev_cert from "../assets/devops-cert.jpg"
import ids_cert from "../assets/ids-cert.PNG"

function Home() {
  return (
    <div className="home-div">
      <div className="home-content">
          <div className='about'>
            <h3 style={{textAlign: 'center', fontSize: '15px'}}>ABOUT</h3>
            <p style={{fontSize: '15px', fontWeight: 100, textAlign: 'center', lineHeight: 1.4}}>
              Aspiring Full Stack Web Developer with hands-on experience building full featured web applications using React, Node.js, and MongoDB. Completed 
              several personal projects that demonstrate a solid understanding of modern 
              web development practices, including responsive design, RESTful APIs, and 
              database management. Practical experience in deploying, securing, 
              optimizing, and managing applications in Linux environments using Nginx and 
              PM2 on VPS. Experienced in Virtualization & Proxmox VE.
            </p>
          </div>
          <div className="certificates" style={{fontSize: '14px'}}>
            <h3 style={{ textAlign: 'left,', fontSize: '15px'}}>CERTIFICATIONS</h3>
            <div className="certificate-item">
              <a href="https://www.udemy.com/certificate/UC-e8617e84-1504-4e05-b2d5-d235ef861748/">
                <img src={web_cert} alt="Udemy certificate image" />
              </a>
              <p>
                Completed a 74-hour course covering full-stack web development, including:
              <ul>
                  <li>
                    Frontend: HTML, CSS, JavaScript, React.
                  </li>
                  <li>
                    Backend: Node.js, Express, MongoDB.
                  </li>
                  <li>
                    Project: Developed a complete website, implementing both client-side and 
                    server-side technologies.
                  </li>
                </ul>
              </p>
            </div>

            <div className="certificate-item">
                <a href="https://www.udemy.com/certificate/UC-889bf2f0-3c26-4de6-847f-2772e25d31c2/">
                  <img src={dev_cert} alt="Udemy certificate image" />
                </a>
                <p>
                  Completed a 56-hour course focused on DevOps tools and practices, including:
                  <ul>
                    <li>
                      Key Technologies: AWS, Linux, Shell Scripting, Jenkins, GitHub Actions, 
                      Ansible, Docker, Kubernetes, Terraform.
                    </li>
                    <li>
                      Hands-On Projects: Applied learned skills in real-world scenarios, 
                      automating deployment pipelines and managing cloud infrastructure
                    </li>
                  </ul>
                </p>
            </div>

            <div className="certificate-item">
              <a href="https://www.linkedin.com/posts/hadi-karaki-375991214_finally-completed-the-internship-programme-activity-7101877619297513473-pgK1?utm_source=share&utm_medium=member_desktop">
                <img src={ids_cert} alt="IDS certificate image" style={{objectFit: "cover"}}/>
              </a>
              <p>
                Web Developer at Integrated Digital Systems (IDS) at IDS, Beirut
                <ul>
                  <li>Build frontend webpages using HTML/HTML5 and CSS</li>
                  <li>Adding interactions to webpages using JavaScript, fetch API, and jQuery</li>
                  <li>Build up database using SQL Server 2012/2014</li>
                  <li>
                    Develop a full website using Visual Studio 2022, ASP.NET/MVC, C# .NET, 
                    and Entity Framework Core (6.0/7.0)
                  </li>
                  <li>Develop Web APIs (.NET Core 5.0/6.0/7.0) to be used along with their website</li>
                  <li>
                    Collaboration and teamwork with collaborative development practices, 
                    code reviews, and project management tools
                  </li>
                </ul>
              </p>
            </div>
        </div>

        <div className="certificates-mobile" style={{ fontSize: '15px' }}>
            <h3 style={{ textAlign: 'center', fontSize: '14px' }}>CERTIFICATIONS</h3>
            <div className="certificate-item-mobile">
              <a href="https://www.udemy.com/certificate/UC-e8617e84-1504-4e05-b2d5-d235ef861748/">
                <img src={web_cert} alt="Udemy certificate image" />
              </a>
            </div>
            <div className="certificate-item-mobile">
              <a href="https://www.udemy.com/certificate/UC-889bf2f0-3c26-4de6-847f-2772e25d31c2/">
                <img src={dev_cert} alt="Udemy certificate image" />
              </a>
            </div>
            <div className="certificate-item-mobile">
              <a href="https://www.linkedin.com/posts/hadi-karaki-375991214_finally-completed-the-internship-programme-activity-7101877619297513473-pgK1?utm_source=combined_share_message&utm_medium=member_desktop_web">
                <img src={ids_cert} alt="IDS certificate image" style={{objectFit: "cover"}}/>
              </a>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Home;