import '../css/ProjectDetails.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const validProjects = ['pharmacy', 'wealthwise', 'ecommerce', 'hbk', 'delivery', 'campsites'];

export default function ProjectDetails() {
    const { project } = useParams();
    const navigate = useNavigate();

    // Redirect to the home page if the project is not valid
    useEffect(() => {
        if (!validProjects.includes(project)) {
            navigate('/');
        }
    }, [project, navigate]);

    // Return null to prevent further rendering if project is invalid
    if (!validProjects.includes(project)) return null;

    // Safely load the image only if the project is valid
    const image = require(`../assets/${project}.png`);

    return (
        <div className="project-details-div">
            <div className="project-details-content">
                <div className="project-details">
                    <div className="project-details-item">
                        <h3 style={{fontWeight: 'normal', color: 'white'}}>{project === 'hbk' ? 'HBK-Airways' : project.substring(0, 1).toUpperCase() + project.substring(1, project.length)}</h3>
                        <div className='details-img'>
                            <a href={project!=='hbk' ? `https://${project}.hadikaraki.net/` : 'https://github.com/HadiKaraki/HBK-Airways'}>
                                <img src={image} alt={project} />
                            </a>
                        </div>
                        <span style={{color: 'grey'}}>Click to visit website</span>
                    {project === "pharmacy" && (
                        <>  <p className='details-paragraph'>
                                A platform for selling pharmaceutical products using:
                            </p>
                            <ul style={{ textAlign: 'left' }}>
                                <li>Node.js, Express, MongoDB for backend</li>
                                <li>React.js for frontend with Mongo Atlas Search</li>
                                <li>Account features: cart, wishlist, order history</li>
                                <li>Email verification & password recovery using JWT</li>
                                <li>Containerized with Docker and Kubernetes</li>
                            </ul>
                        </>
                    )}
                    {project === "wealthwise" && (
                        <>  <p className='details-paragraph'>
                                A comprehensive personal finance web application to help users 
                                track their expenses, income, budgets, total balance, and savings. Key 
                                features include:
                            </p>
                            <ul style={{textAlign: 'left'}}>
                                <li>
                                    Dashboard Overview: Displays a summary of the user's financial 
                                    data, including total balance, budgets, savings, income, and 
                                    expenses.
                                </li>
                                <li>
                                    Budget Allocation: Allows users to set an initial balance and allocate 
                                    monthly budgets for better financial planning
                                </li>
                                <li>
                                    Transaction Management: Provides dedicated pages for tracking 
                                    income and expenses on a well-organized table with detailed entries
                                </li>
                                <li>
                                    Reports & Visualization: Includes an interactive reports page with line 
                                    and pie charts, enabling users to analyze financial trends on a 
                                    weekly, monthly, or yearly basis, customizable in the settings.
                                </li>
                                <li>
                                    Account Management: Enables users to update their profile details, 
                                    including username, email, and password.
                                </li>
                            </ul>
                        </>
                    )}
                    {project === "campsites" && (
                        <>
                            <p className='details-paragraph'>
                                View campsites Around the World. A campsite discovery platform using:
                            </p>
                            <ul style={{ textAlign: 'left' }}>
                                <li>Node.js, Express, MongoDB for backend</li>
                                <li>Mongo Atlas Search to search for campsites</li>
                                <li>The Mapbox API where campsites are displayed accurately on the word map</li>
                                <li>Users can add reviews and ratings</li>
                            </ul>
                        </>
                    )}
                    {project === "delivery" && (
                        <>
                            <p className='details-paragraph'>
                            Delivery Service Website for delivering food. Users can explore restaurants, view their menus, and place orders:
                            </p>
                            <ul style={{ textAlign: 'left' }}>
                                <li>Node.js, Express, MongoDB for backend</li>
                                <li>React.js for frontend with Mongo Atlas Search</li>
                                <li>Account features: cart, wishlist, order history</li>
                                <li>Email verification & password recovery using JWT</li>
                            </ul>
                        </>
                    )}
                    {project === "ecommerce" && (
                        <>
                            <p className='details-paragraph'>
                            An E-commerce website for selling electronics, ranging from televisions, mobile phones, 
                            cameras, laptops etc. It features a search bar that allows users to search for products. 
                            The search engine used is Mongo atlas search
                            </p>
                            <ul style={{ textAlign: 'left' }}>
                                <li>Node.js, Express, MongoDB for backend</li>
                                <li>MongoDB Atlas as databse</li>
                                <li>Cart and Wishlist features</li>
                                <li>with Mongo Atlas Search engine to search for products</li>
                            </ul>
                        </>
                    )}
                    {project === "hbk" && (
                        <>
                            <p className='details-paragraph'>
                            An airline reservation website made as a project in a group of three and was the basis for 
                            learning PHP professionally. This website is a complete airline reservation website that allows users 
                            to create an account and book and reserve flights. An admin user adds flights to 
                            the database using a user interface made for this purpose for normal users to search for:
                            </p>
                            <ul style={{ textAlign: 'left' }}>
                                <li>HTML, CSS, JavaScript and PHP as frontend</li>
                                <li>MongoDB as database</li>
                                <li>XAMPP for hosting</li>
                                <li>Search engine that allows users to search for flights</li>
                                <li>Users create accounts, book flights or reserve</li>
                            </ul>
                        </>
                    )}
                    </div>
                </div>
            </div>
        </div>
    );
}
